const main = {
  'Channel Integration': 'サードパーティサービス',
  TikTok: 'TikTok',
  'TikTok for Business Title': 'TikTok ビジネス拡張機能',
  'TikTok for Business Description':
    '急成長中のソーシャルネットワークで積極的な消費者に商品を紹介し、より多くの人にリーチしましょう！',
  'Install now': '今すぐインストール',
  'Learn more': '詳細はこちら',
  'Connected TikTok Account': '接続済みの TikTok アカウント',
  'Basic setting': '基本設定',
  'TikTok for Business Account Title': 'ビジネス向けTikTokアカウント',
  'TikTok for Business Account Description':
    '現在の TikTok アカウントで管理者権限を持つビジネスアカウントに接続します',
  'Go to Business Center': 'ビジネスセンターへ',
  'TikTok Catalog': 'TikTok Catalog',
  'TikTok Catalog Description':
    'TikTokで宣伝または販売したいすべての商品に関する情報を管理します',
  Advertising: '広告',
  'Advertising Account Title': '広告アカウント',
  'Advertising Account Description':
    '広告費用を請求する広告アカウントへのリンク',
  'TikTok Pixel': 'TikTok Pixel',
  'TikTok Pixel Description':
    'Pixel はオンライン ストアでの顧客の行動を追跡します。この情報はマーケティング能力の向上に役立ちます',
  'Disconnect Current Account': '現在のアカウントのリンクを解除',
  'Disconnect Popup Title': 'TikTokアカウントのリンクを解除しますか?',
  'Disconnect Popup Description':
    '切断後は、TikTok for Business のすべての機能が利用できなくなります',
  'Disconnect Popup Confirm': '切断',
  Cancel: 'キャンセル',
  'Connection Error Alert':
    '接続エラー。アセットのステータスを確認して再接続してください。',
  'LEARN MORE LINK':
    'https://support.shoplineapp.com/hc/en-us/articles/4407185010329',
  Email: 'メールアドレス: {{email}}',
  Connect: '接続する',
  'TikTok AccessToken Invalid Alert':
    'アセットの権限が期限切れになっているため、広告を配置できません。TikTok 広告を再度配置するには、[接続] をクリックして TikTok for Business アセットを再接続してください。',
  'TikTok for Business Account Hint':
    '接続されていません。広告を掲載したい場合は、「アセットを接続」ボタンを押して、TikTok For Business アカウントを選択または作成してください。',
  'TikTok Pixel Hint':
    '接続されていません。広告を配置したい場合は、「アセットを接続」ボタンを押して TikTok ピクセルを選択または作成してください。',
  'TikTok Ads Hint':
    '接続されていません。広告を掲載したい場合は、「アセット接続」をクリックして広告アカウントを選択または作成してください。',
  'TikTok Catalog Hint':
    '接続されていません。広告を掲載したい場合は、「アセット接続」をクリックして商品カタログを選択または作成してください。',
  'Edit Assets': 'アセットの編集',
  'Manage Settings': '設定管理',
  'TikTok Update Success':
    'TikTok アセットが更新されました。\n基本設定と広告でアセットを管理できます',
};

export default main;
